import React from 'react';

const IconLogo = () => (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="84.000000pt" height="96.000000pt" viewBox="0 0 486.000000 475.000000"
 preserveAspectRatio="xMidYMid meet">
    <title>Logo</title>
<g transform="translate(0.000000,475.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M1365 4102 l-930 -548 -3 -344 -2 -345 382 -224 c211 -123 503 -294
650 -380 l267 -156 1 -493 0 -493 -38 23 c-22 13 -177 106 -345 208 l-307 185
-2 303 -3 304 -300 169 -300 169 -3 -637 c-2 -502 1 -640 10 -649 16 -14 1853
-1094 1862 -1094 3 0 5 527 4 1171 l-3 1171 -630 379 c-346 208 -631 382 -632
386 -2 4 151 101 340 215 l342 206 3 -228 2 -228 283 -167 c155 -93 285 -170
290 -172 4 -2 7 406 7 906 0 501 -3 911 -7 911 -5 -1 -426 -247 -938 -548z"
fill="currentColor"/>
<path d="M2580 2375 c0 -1251 3 -2275 8 -2275 4 0 432 247 952 548 l945 547 0
510 0 510 -138 79 c-89 52 -133 83 -125 88 7 4 69 40 138 80 l125 73 0 510 0
510 -945 547 c-520 301 -948 548 -952 548 -5 0 -8 -1024 -8 -2275z m1080 958
l205 -118 0 -240 0 -239 -312 -180 -312 -181 82 -47 c45 -26 186 -108 312
-181 l230 -133 0 -239 0 -239 -200 -116 c-110 -64 -271 -157 -357 -208 l-158
-91 0 1254 0 1254 153 -88 c83 -49 244 -142 357 -208z"
fill="currentColor"/>
</g>
</svg>

);

export default IconLogo;
